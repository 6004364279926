@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}
.amountInput {
  width: 100px;
  margin-left: 15px;
  @include placeholder {
    color: #495057;
  }
}
